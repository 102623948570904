<template>
<div class="table-add" v-loading.fullscreen.lock="loading">
    <div class="table-add-header">
        <h1>Edit Calkulator page</h1>
        <div class="flex mt-20">
            <button class="table-lang-button" type="button" @click="langName='ua'" :class="langName=='ua'?'active':''">UA</button>
            <button class="table-lang-button ml-10" type="button" @click="langName='en'" :class="langName=='en'?'active':''">ENG</button>
        </div>
        <div class="add-inputs" style="max-width:1000px;">
            <el-form ref="pagesForm" :model="Pages" :rules="PagesRules">
                <div class="form-au" v-show="langName=='ua'">
                    <div class="item mt-20">
                        <p>Title</p>
                        <el-form-item prop="data.title_ua">
                            <el-input type="text" v-model="Pages.data.title_ua"></el-input>
                        </el-form-item>
                    </div> 
                    <div class="item mt-20">
                        <p>Type 1</p>
                        <el-form-item prop="data.type1_ua">
                            <el-input type="text" v-model="Pages.data.type1_ua"></el-input>
                        </el-form-item>
                    </div> 
                    <div class="item mt-20">
                        <p>Type 2</p>
                        <el-form-item prop="data.type2_ua">
                            <el-input type="text" v-model="Pages.data.type2_ua"></el-input>
                        </el-form-item>
                    </div> 
                    <div class="item mt-20">
                        <p>Count</p>
                        <el-form-item prop="data.min_sum">
                            <el-input type="text" v-model="Pages.data.count_ua"></el-input>
                        </el-form-item>
                    </div> 
                    <div class="item mt-20">
                        <p>Term</p>
                        <el-form-item prop="data.min_sum">
                            <el-input type="text" v-model="Pages.data.term_ua"></el-input>
                        </el-form-item>
                    </div> 
                    <div class="item mt-20">
                        <p>Month</p>
                        <el-form-item prop="data.month_ua">
                            <el-input type="text" v-model="Pages.data.month_ua"></el-input>
                        </el-form-item>
                    </div> 
                    
                    <div class="item mt-20">
                        <p>Начислення</p>
                        <el-form-item prop="data.min_sum">
                            <el-input type="text" v-model="Pages.data.accrual_ua"></el-input>
                        </el-form-item>
                    </div> 
                    <div class="item mt-20">
                        <p>Погашення</p>
                        <el-form-item prop="data.min_sum">
                            <el-input type="text" v-model="Pages.data.redemption_ua"></el-input>
                        </el-form-item>
                    </div> 
                    <div class="item mt-20">
                        <p>Повернення</p>
                        <el-form-item prop="data.min_sum">
                            <el-input type="text" v-model="Pages.data.turning_ua"></el-input>
                        </el-form-item>
                    </div> 
                    <div class="item mt-20">
                        <p>Проценти</p>
                        <el-form-item prop="data.min_sum">
                            <el-input type="text" v-model="Pages.data.percent_ua"></el-input>
                        </el-form-item>
                    </div> 
                    <div class="item mt-20">
                        <p>Button title</p>
                        <el-form-item prop="data.min_sum">
                            <el-input type="text" v-model="Pages.data.button_ua"></el-input>
                        </el-form-item>
                    </div> 
                     <div class="item mt-20">
                        <p>Url</p>
                        <el-form-item prop="data.min_sum">
                            <el-input type="text" v-model="Pages.data.url"></el-input>
                        </el-form-item>
                    </div> 


                    <div class="item mt-20">
                        <p>Min Summ</p>
                        <el-form-item prop="data.min_sum">
                            <el-input type="text" v-model="Pages.data.min_sum" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"></el-input>
                        </el-form-item>
                    </div> 
                    <div class="item mt-20">
                        <p>Max Summ</p>
                        <el-form-item prop="data.max_sum">
                            <el-input v-model="Pages.data.max_sum" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"></el-input>
                        </el-form-item>
                    </div> 
                    <div class="item mt-20">
                        <p>Min Month</p>
                        <el-form-item prop="data.min_month">
                            <el-input v-model="Pages.data.min_month" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"></el-input>
                        </el-form-item>
                    </div> 
                    <div class="item mt-20">
                        <p>Max Month</p>
                        <el-form-item prop="data.max_month">
                            <el-input v-model="Pages.data.max_month" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"></el-input>
                        </el-form-item>
                    </div> 
                    <div class="item mt-20">
                        <p>Month protcent</p>
                        <el-form-item prop="data.percent_month">
                            <el-input v-model="Pages.data.percent_month" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"></el-input>
                        </el-form-item>
                    </div> 
                    <div class="item mt-20">
                        <p>Year protcent</p>
                        <el-form-item prop="data.percent_year">
                            <el-input v-model="Pages.data.percent_year" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"></el-input>
                        </el-form-item>
                    </div> 
                     

                </div>
                <div class="form-en mt-20" v-show="langName=='en'">
                     <div class="item mt-20">
                        <p>Title</p>
                        <el-form-item prop="data.title_en">
                            <el-input type="text" v-model="Pages.data.title_en"></el-input>
                        </el-form-item>
                    </div> 
                    <div class="item mt-20">
                        <p>Type 1</p>
                        <el-form-item prop="data.type1_en">
                            <el-input type="text" v-model="Pages.data.type1_en"></el-input>
                        </el-form-item>
                    </div> 
                    <div class="item mt-20">
                        <p>Type 2</p>
                        <el-form-item prop="data.type2_en">
                            <el-input type="text" v-model="Pages.data.type2_en"></el-input>
                        </el-form-item>
                    </div> 
                    <div class="item mt-20">
                        <p>Count</p>
                        <el-form-item prop="data.count_en">
                            <el-input type="text" v-model="Pages.data.count_en"></el-input>
                        </el-form-item>
                    </div> 
                    <div class="item mt-20">
                        <p>Term</p>
                        <el-form-item prop="data.term_en">
                            <el-input type="text" v-model="Pages.data.term_en"></el-input>
                        </el-form-item>
                    </div> 
                    <div class="item mt-20">
                        <p>Month</p>
                        <el-form-item prop="data.month_en">
                            <el-input type="text" v-model="Pages.data.month_en"></el-input>
                        </el-form-item>
                    </div> 
                    
                    <div class="item mt-20">
                        <p>Начислення</p>
                        <el-form-item prop="data.accrual_en">
                            <el-input type="text" v-model="Pages.data.accrual_en"></el-input>
                        </el-form-item>
                    </div> 
                    <div class="item mt-20">
                        <p>Погашення</p>
                        <el-form-item prop="data.redemption_en">
                            <el-input type="text" v-model="Pages.data.redemption_en"></el-input>
                        </el-form-item>
                    </div> 
                    <div class="item mt-20">
                        <p>Повернення</p>
                        <el-form-item prop="data.turning_en">
                            <el-input type="text" v-model="Pages.data.turning_en"></el-input>
                        </el-form-item>
                    </div> 
                    <div class="item mt-20">
                        <p>Проценти</p>
                        <el-form-item prop="data.percent_en">
                            <el-input type="text" v-model="Pages.data.percent_en"></el-input>
                        </el-form-item>
                    </div> 
                    <div class="item mt-20">
                        <p>Button title</p>
                        <el-form-item prop="data.button_en">
                            <el-input type="text" v-model="Pages.data.button_en"></el-input>
                        </el-form-item>
                    </div>  
                </div>

                <div class="save-buttons">
                    <button class="button" type="button" @click="$router.push('/admin/statpages')"> {{$t('back')}} </button>
                    <button class="button save ml-10" type="button" @click="PostStock()"> Edit</button>
                </div>
            </el-form>
        </div>
    </div> 
</div>
</template>

<script>
export default {
    data() {
        return {
            checked: false,
            langName: 'ua',
            fileList: [],
            value1: '',
            form: {
                name: ''
            },
            loading: false,
            erros: {
                image: false,
                en: false,
                ua: false
            },
            
            Pages: {
                key: "/calculator",
                data: {
                    title_ua:'',
                    title_en:'',
                    type1_ua:'',
                    type1_en:'',
                    type2_ua:'',
                    type2_en:'',
                    count_ua:'',
                    count_en:'',
                    term_ua:'',
                    term_en:'',
                    accrual_ua:'',
                    accrual_en:'',
                    redemption_ua:'',
                    redemption_en:'',
                    turning_ua:'',
                    turtning_en:'',
                    percent_ua:'',
                    percent_en:'',
                    button_ua:'',
                    button_en:'',
                    url:'',
                     max_sum:1000000,
                     min_sum:1000,
                     max_month:24,
                     min_month:1,
                     percent_month:10,
                     percent_year:10

                },
                files: {
                    
                }
            },
            PagesRules: {
                data: {
                    max_sum: [   
                        { required: true,   message: 'Введіть номер', trigger: 'change' },
                        { min: 4, max: 8, message: 'Это поле должно содержать минимум [4 8] символа!', trigger: 'change' },
                    ],
                    min_sum:[   
                        { required: true,   message: 'Введіть номер', trigger: 'change' },
                        { min: 4, max: 8, message: 'Это поле должно содержать минимум [4 8] символа!', trigger: 'change' },
                    ],
                    max_month:[   { required: true,  message: 'Введіть номер', trigger: 'change' } ],
                    min_month:[   { required: true,   message: 'Введіть номер', trigger: 'change' } ],
                    percent_month:[   { required: true,  message: 'Введіть номер', trigger: 'change' } ],
                    percent_year:[   { required: true,   message: 'Введіть номер', trigger: 'change' } ],
                }
                
            },
        }

    },

    methods: {
        UploadIMage(event, name) {
            this.Pages.files[`${name}`] = event.target.files[0]
        },
        PostStock() {

            this.$refs.pagesForm.validate((valid) => {
                if (valid) {
                    this.loading = true
                    let files = new FormData();
                    var pageData = {}
                    if (typeof this.Pages.data != 'string') {
                        pageData = JSON.stringify(this.Pages.data)
                    }
                    files.append('key', this.Pages.key)
                    files.append('data', pageData)
                    files.append('files', null)
                     

                    this.axios.post(this.Pages.id? `/admin/statpage/${this.Pages.id}`:'/admin/statpage', files).then(res => {
                            console.log(res)
                            this.$router.push('/admin/statpages')
                            this.$notify({
                                title: 'Успешный',
                                message: 'Информация успешно сохранена',
                                type: 'success'
                            });
                            this.$store.dispatch("get_statpages");
                        })
                        .catch(error => {
                            this.erorrsData = error.response.data.message
                            let ERRORS = error.response.data.errors;
                            let keys = Object.keys(ERRORS);
                            let error_msg = ERRORS[keys[0]];
                            console.log(error_msg[0])
                            this.$notify.error({
                                title: 'Произошла ошибка',
                                message: error_msg ? error_msg[0] : "Произошла ошибка",
                            });

                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    console.log("AAA")
                }
            })

        },
        GetPages() {
            this.loading=true
            this.axios.get('/admin/statpage')
                .then(res => {
                    var data = res.data.data 
                    
                    var home = data.filter(a => {
                        return a.key === '/calculator'
                    })
                    this.fileList=home
                    if (home[0]) {
                        this.Pages.id = home[0].id
                        this.Pages.key = home[0].key
                        this.Pages.data = home[0].data
                        for (let i of Object.keys(home[0].files)) {
                            this.Pages.files[i] = home[0].files[i]
                        }

                    }
                })
                .finally(() => {
                    this.loading = false
                })
        } 

    },
    mounted() {
        this.GetPages()
    }
}
</script>

<style lang="scss" scoped>
.date-line {
    width: 30px;
    height: 3px;
    background: rgba(69, 72, 81, 0.8);
    border-radius: 5px;
    margin: 0px 10px;
}

.save-buttons {
    padding-top: 20px;
}

.upload-image {
    width: 100%;
    display: flex;
    align-items: center;

    p {
        margin-left: 10px;
    }

    button {
        width: 200px;
        position: relative;
        overflow: hidden;
        padding: 10px 15px;
        border-radius: 5px;
        color: var(--white-color);
        font-family: Stem-Medium;
        font-size: 1rem;
        line-height: 1.125rem;
        border: none;
        color: var(--black);

        input {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
            z-index: 1;
        }
    }
}

.admin-h2-title {
    margin: 20px 0px 10px 0px;
    font-family: Stem-Medium;
    color: var(--other-black);
}

.error-text {
    color: red;
    font-size: 0.875rem;
    margin-top: 5px;
}

.c-red {
    color: red !important;
}

.add-inputs {
    h1 {
        margin: 20px 0px;
        font-size: 24px;
    }
}
</style>
